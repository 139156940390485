import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrScanTimePicker from '@/modules/common/components/ui-core/gtr-scantime-picker/gtr-scantime.picker.vue'
import { format } from 'date-fns'
@Component({
  name: 'GtrAddAttendeeForm',
  components: {
    'gtr-scantime-picker': GtrScanTimePicker
  },
  computed: {
    ...mapState('attendee', ['attendees'])
  }
})
export default class GtrAddAttendeeForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: Object, default: { uuid: null } })
    session: any;

    attendees!: Record<string, any>

    submitting = false;
    loading = false;
    showForm = false;
    showTimePicker = false;

    dateTime = '';

    data () {
      return {
        table: {
          search: '',
          selected: [],
          items: [],
          headers: [
            { text: 'First name', align: 'start', sortable: false, value: 'first_name' },
            { text: 'Last name', align: 'start', sortable: false, value: 'last_name' },
            { text: 'Email', align: 'start', sortable: false, value: 'email' },
            { text: 'Date Scanned In', align: 'start', sortable: false, value: 'date_scanned_in' },
            { text: 'Date Scanned Out', align: 'start', sortable: false, value: 'date_scanned_out' },
            { text: 'Add Scan', align: 'start', sortable: false, value: 'add_scan_btn' }
          ]
        },
        giveAccessForm: false,
        unselected: [],
        participantScanHash: {}
      }
    }

    get attendeesWithAccess () {
      return this.session.session_access
    }

    get attendeesWithAccessIds () {
      return this.attendeesWithAccess.map(({ participant_short }) => participant_short.uuid)
    }

    get selectedAttendees () {
      return this.$data.table.selected.map(selection => selection.attendee_uuid)
    }

    async mounted () {
      if (!this.$store.state.event.eventAllContent) {
        await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
      }
      await this.fetchData()
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.showForm = newVisibleValue
    }

    // utility function that batches the data fetching for this component.
    async fetchData (): Promise<void> {
      await this.fetchAttendees()
      await this.processTableData()
      await this.determineWhoHasAccess()
      await this.getSessionTrackingData()
    }

    /**
     * cross reference session access and attendee list to set up table selection.
     */
    private async determineWhoHasAccess () {
      // get current table items.
      const attendees = this.$data.table.items
      // get uuids of all attendees with access.
      const attendeesWithAccess = this.attendeesWithAccess.map(({ participant_short }) => participant_short.uuid)
      // set selected options to attendess that are in the access list.
      this.$data.table.selected = attendees.filter(attendee => (attendeesWithAccess.includes(attendee.attendee_uuid)))
    }

    /**
     * fetches attendees for the event.
     */
    private async fetchAttendees (): Promise<void> {
      try {
        this.loading = true
        await this.$store.dispatch('attendee/fetchAttendees', this.$route.params.event_uuid)
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.loading = false
      }
    }

    private async processTableData (): Promise<void> {
      this.$data.table.items = []
      const fieldsToDisplay = ['first_name', 'last_name', 'email', 'date_scanned']
      this.attendees.data.forEach(attendee => {
        const row: any = {}
        fieldsToDisplay.forEach(field => {
          row[field] = attendee.participant_data[field] ?? null
          row.attendee_uuid = attendee.uuid
        })

        if (Object.keys(this.$data.participantScanHash).length > 0) {
          row.date_scanned = this.$data.participantScanHash[attendee.uuid]
        }
        this.$data.table.items.push(row)
      })
    }

    handleShowTimePicker (visibility: boolean): void {
      this.showTimePicker = visibility
    }

    onClose () {
      this.$emit('close')
      this.cleanSelected()
    }

    cleanSelected () {
      this.$data.table.selected = []
    }

    /**
     * takes a date as a string and returns a date time formatted to local timezone.
     *
     * @param      {string}  time    The time
     * @return     {string}  { the formatted datetime or an empty string }
     */
    private formatDateTime (time: string): string {
      try {
        if (!time) return ''
        const date = new Date(time)
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
        return date.toLocaleString()
      } catch (err) {
        Container.get(Notification).error('formateDateTime: Error formatting time')
        return ''
      }
    }

    /**
     * click handler that updates access for participants.
     */
    async handleAccess (): Promise<void> {
      const { selected } = this.$data.table
      const { unselected } = this.$data
      try {
        this.loading = true
        await this.removeAccess(unselected)
        await this.giveAccess(selected)
        Container.get(Notification).success('Session access updated.')
        await this.getSessionTrackingData()
        this.$emit('attendance-updated')
      } catch (error) {
        Container.get(Notification).error((error as Error).message)
      } finally {
        this.loading = false
      }
    }

    /**
     * adds scan info for the passed participant.
     * @param attendee_uuid of participant on the data table.
     */
    async addScan ({ attendee_uuid, scan_date }: {
      attendee_uuid: string;
      scan_date: Date;
    }): Promise<void> {
      const date = format(scan_date, 'yyyy-MM-dd HH:mm:ss')
      const { event_uuid } = this.$route.params
      const { uuid: session_uuid } = this.session
      try {
        await this.$store.dispatch('sessions/giveBulkAttendance', {
          session_uuid,
          event_uuid,
          data: {
            participant_uuids: [attendee_uuid],
            date_scanned: date
          }
        })
        await this.getSessionTrackingData()
        this.$emit('attendance-updated')
      } catch (error) {
        Container.get(Notification).error('there was an error adding the scan')
      }
    }

    async addScanToAttendeesWithAccess (): Promise<void> {
      const { event_uuid } = this.$route.params
      const { uuid: session_uuid } = this.session
      try {
        const participant_uuids: string[] = this.$data.table.selected.map(({ attendee_uuid }) => attendee_uuid)
        await this.$store.dispatch('sessions/giveBulkAttendance', {
          session_uuid,
          event_uuid,
          data: {
            participant_uuids
          }
        })
        await this.getSessionTrackingData()
        this.$emit('attendance-updated')
      } catch (error) {
        Container.get(Notification).error('Error: Adding scans to session attendees')
      }
    }

    private async fetchSessionAttendance (): Promise<any> {
      try {
        this.loading = true
        const { event_uuid } = this.$route.params
        const { uuid: session_uuid } = this.session
        await this.$store.dispatch('sessions/getSessionAttendance', {
          event_uuid,
          session_uuid
        })
      } catch (error) {
        Container.get(Notification).error('there was a problem fetching session attendance')
      } finally {
        this.loading = false
      }
    }

    /**
     * Gets the session tracking data.
     *
     * @return     {Promise<void>}  The session tracking data.
     */
    async getSessionTrackingData (): Promise<void> {
      try {
        const { event_uuid } = this.$route.params
        this.loading = true
        const { data } = await this.$store.dispatch('sessions/getSessionTrackingData', {
          event_uuid: event_uuid,
          session_uuid: this.session.uuid
        })
        this.mergeTrackingData(data.scan_data)
      } catch (err) {
        Container.get(Notification).error((err as Error).message)
      } finally {
        this.loading = false
      }
    }

    async editAccess () {
      try {
        if (this.$data.table.selected.length) {
          await this.giveAccess(this.$data.table.selected)
        }

        if (this.$data.unselected.length) {
          await this.removeAccess(this.$data.unselected)
        }
        Container.get(Notification).success('Access successfully edited.')
        this.$emit('attendance-updated')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.loading = false
      }
    }

    /**
     * giveAccess takes a list of selected participants.
     * does a diff with the current list of attendees with access.
     * Sends the diff to be given access.
     * @param selected - list of selected participants.
     */
    async giveAccess (selected: Array<Record<string, any>>): Promise<void> {
      if (!selected.length) return // none selected exit early.
      // diff the selected attendess against attendees with access.
      const attendeesToGrantAccess = this.diff(this.attendeesWithAccess, selected)
      if (!attendeesToGrantAccess.length) return // if no one needs access, exit the function.
      // now filter the selected
      try {
        const { uuid: session_uuid } = this.session
        const { event_uuid } = this.$route.params
        await this.$store.dispatch('sessions/giveBulkAccess', {
          session_uuid,
          event_uuid,
          data: { participant_uuids: attendeesToGrantAccess }
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }

    /**
     * diff will take two lists of attendees and return a list of ids of attendees who don't already have access.
     * @param withAccess: list of attendees with access.
     * @param selected: list of attendees that are selected.
     * @returns Array of uuids
     */
    private diff (
      withAccess: Array<Record<string, any>>,
      selected: Array<Record<string, any>>
    ): Array<string> {
      // first unpack the uuids.
      const uuidsOfAccess = withAccess.map(({ participant_short }) => participant_short.uuid)
      const uuidsOfSelected = selected.map(({ attendee_uuid }) => attendee_uuid)
      // then determine which of the selected participants don't already have access. and return it.
      return uuidsOfSelected.filter(uuid => !uuidsOfAccess.includes(uuid))
    }

    async removeAccess (unselected: Array<Record<string, any>>): Promise<void> {
      try {
        if (!unselected.length) return
        const { uuid: session_uuid } = this.session
        const { event_uuid } = this.$route.params
        const participant_uuids = unselected.map(({ attendee_uuid }) => attendee_uuid)
        await this.$store.dispatch('sessions/removeBulkAccess', {
          session_uuid,
          event_uuid,
          data: { participant_uuids }
        })
        await this.$store.dispatch('sessions/removeBulkAttendance', {
          session_uuid,
          event_uuid,
          data: { participant_uuids }
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }

    /**
     * takes session tracking data and merges into attendee data.
     *
     * @param      {Object}  data    The data
     */
    private mergeTrackingData (data: Array<Record<string, any>>): void {
      // store a copy of the items.
      const attendees = this.$data.table.items
      // if not attendees, that means no one is registered to the event.
      if (attendees.length === 0) {
        // Not an error, but still inform user.
        // then exit.
        Container.get(Notification).warning('No attendees are signed up to this event')
        return
      }

      // go through each attendee and check if they've been scanned.
      // If not continue to next attendee.
      // otherwise merge in in and out scan.
      for (const attendee of attendees) {
        const attendeeScanData = data
          .filter(({ participant_short }) => participant_short.uuid === attendee.attendee_uuid)
          .sort()
        const scanDataLength = attendeeScanData.length
        if (scanDataLength === 0) {
          // if no scan data, set date_scanned to null.
          attendee.date_scanned_in = null
          attendee.date_scanned_out = null
        } else {
          // sort the data.
          attendee.date_scanned_in = attendeeScanData[0].date_scanned
          attendee.date_scanned_out = attendeeScanData.length > 1 ? attendeeScanData[scanDataLength - 1].date_scanned : null
        }
      }
      // finally replace tables items with merged data.
      this.$data.table.items = attendees
    }

    /**
     * handles updating the unselected list.
     * @param item
     */
    handleItemSelected (item: any): void {
      if (!item.value) {
        // add the item to the unselected list.
        this.$data.unselected.push(item.item)
      } else {
        // get the index of the item and splice it out of the unselected array.
        const index = this.$data.unselected.indexOf(item.item)
        this.$data.unselected.splice(index, 1)
      }
    }

    selectAllToggle (items) {
      if (!items.value) {
        this.$data.unselected = items.items
        this.$data.table.selected = []
      } else {
        this.$data.table.selected = items.items
        this.$data.unselected = []
      }
    }
}
