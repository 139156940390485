import { format, parse } from 'date-fns'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const DEFAULT_DATE = ''
const DEFAULT_TIME = '00:00:00'
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_OK_TEXT = 'OK'
const DEFAULT_TIME_FORMAT_SHORT = 'HH:mm'
const DEFAULT_BUTTON_TEXT = 'Click Here'

@Component({
  name: 'GtrScanTimePicker'
})
export default class GtrScanTimePicker extends Vue {
  DEFAULT_DATE = ''
  DEFAULT_TIME = '00:00:00'
  DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
  DEFAULT_TIME_FORMAT = 'HH:mm:ss'
  DEFAULT_DIALOG_WIDTH = 340
  DEFAULT_CLEAR_TEXT = 'CLEAR'
  DEFAULT_OK_TEXT = 'OK'
  DEFAULT_TIME_FORMAT_SHORT = 'HH:mm'

  @Prop({ type: Boolean, default: false })
  disabled?: boolean;

  @Prop({ type: Date })
  datetime?: string;

  @Prop({ type: String, required: true })
  sessionStart!: string;

  @Prop({ type: String, required: true })
  sessionEnd!: string;

  @Prop({ type: String, required: true })
  attendeeUuid!: string;

  @Prop({ type: Boolean })
  loading?: boolean;

  @Prop({ type: String, default: DEFAULT_DATE_FORMAT })
  dateFormat?: string;

  @Prop({ type: String, default: DEFAULT_TIME_FORMAT_SHORT })
  timeFormat?: string;

  @Prop({ type: String, default: DEFAULT_CLEAR_TEXT })
  clearText?: string;

  @Prop({ type: String, default: DEFAULT_OK_TEXT })
  okText?: string;

  @Prop({ type: String, default: DEFAULT_BUTTON_TEXT })
  buttonText?: string;

  data () {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    }
  }

  // COMPUTED PROPERTIES
  get dateTimeFormat () {
    return this.dateFormat + ' ' + this.timeFormat
  }

  get defaultDateTimeFormat () {
    return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT
  }

  get formattedDatetime () {
    return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : ''
  }

  get selectedDatetime () {
    if (this.$data.date && this.$data.time) {
      let datetimeString = this.$data.date + ' ' + this.$data.time
      if (this.$data.time.length === 5) {
        datetimeString += ':00'
      }
      return parse(datetimeString, this.defaultDateTimeFormat, new Date())
    } else {
      return null
    }
  }

  get dateSelected () {
    return !this.$data.date
  }

  mounted () {
    this.init()
  }

  selectDate () {
    this.$data.activeTab = 1
  }

  // METHODS
  init () {
    if (!this.datetime) {
      return
    }
    let initDateTime
    if (this.$data.datetime instanceof Date) {
      initDateTime = this.$data.datetime
    } else if (typeof this.$data.datetime === 'string' || this.$data.datetime instanceof String) {
      initDateTime = parse(this.datetime, this.dateTimeFormat, new Date())
    }
    this.$data.date = format(initDateTime, DEFAULT_DATE_FORMAT)
    this.$data.time = format(initDateTime, DEFAULT_TIME_FORMAT)
  }

  okHandler () {
    this.resetPicker()
    this.$emit('date-selected', {
      attendee_uuid: this.attendeeUuid,
      scan_date: this.selectedDatetime
    })
  }

  clearHandler () {
    this.resetPicker()
    this.$data.date = DEFAULT_DATE
    this.$data.time = DEFAULT_TIME
  }

  resetPicker () {
    this.$data.display = false
    this.$data.activeTab = 0
    if (this.$refs.timer) {
      (this.$refs.timer as any).selectingHour = true
    }
    (this.$refs.menu as any).save()
  }

  showTimePicker () {
    this.$data.activeTab = 1
  }

  onClickOutside (event) {
    if (event.target.className === 'v-overlay__scrim') {
      this.clearHandler()
    }
  }

  // WATCHERS
  @Watch('datetime')
  refreshDate () {
    this.init()
  }
}
