import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import GtrAddAttendeeForm from './forms/add-attendee.form.vue'
import GtrStorage from '@/modules/common/services/storage.service'

@Component({
  name: 'GtrEventSessionsView',
  computed: {
    ...mapState('event', ['event']),
    ...mapState('sessions', ['sessions', 'attendanceCounts']),
    ...mapGetters('module', ['surveyModuleActive']),
    ...mapState('email', ['emails'])
  },
  components: {
    'gtr-add-attendee-form': GtrAddAttendeeForm
  }
})
export default class GtrEventSessionsView extends GtrSuper {
  sessions!: Array<Record<string, any>>;
  loading = false;
  surveyModuleActive!: boolean;

  get sessionParticipantUuidsHash () {
    const { sessionsParticipantCounts = [] } = (this as any).attendanceCounts
    const hash = {}
    for (const sessionCount of sessionsParticipantCounts) {
      const { session: { uuid: sessionId = null }, scan_data = [] } = sessionCount
      if (sessionId) {
        const participantUuids = scan_data.map(scan => scan?.participant_short?.uuid).filter(scan => scan)
        hash[sessionId] = participantUuids
      }
    }
    return hash
  }

  get emailOptions () {
    if ((this as any).emails) {
      const emailOptions: any[] = []
      for (let i = 0; i < (this as any).emails.length; i++) {
        const email = (this as any).emails[i]
        const emailName = this.handleEmailName(email.value.page_name)
        const emailValue = email.value.page_name
        if (emailValue !== 'admin_cancellation_notification' && emailValue !== 'admin_registration_notification') {
          emailOptions.push({
            text: emailName,
            value: emailValue
          })
        }
      }
      return emailOptions.sort((a, b) => a.text >= b.text ? 1 : -1)
    }
    return []
  }

  get tableHeaders (): Record<string, any>[] {
    if (this.surveyModuleActive) {
      return this.$data.table.headers
    } else {
      return this.$data.table.headers.filter(header => header.value !== 'credits')
    }
  }

  data () {
    return {
      showEmailAttendeesModal: false,
      showEmailAttendeesModalSessionId: '',
      emailToSend: '',
      table: {
        search: '',
        headers: [
          { text: 'Name', align: 'start', sortable: true, value: 'name' },
          { text: 'Start', align: 'center', sortable: true, value: 'start_date' },
          { text: 'End', align: 'center', sortable: true, value: 'end_date' },
          { text: 'Category', align: 'center', sortable: true, value: 'category' },
          { text: 'Scans', align: 'center', sortable: true, value: 'total_scans' },
          { text: '# of Participants', align: 'center', sortable: true, value: 'unique_scans' },
          { text: 'Status', align: 'center', sortable: true, value: 'status' },
          { text: 'Scan Mode', align: 'center', sortable: true, value: 'scan_modes' },
          { text: 'Credits', align: 'center', sortable: true, value: 'credits' },
          { text: 'Speaker', align: 'center', sortable: true, value: 'session_speakers' },
          { text: 'Actions', sortable: false, value: 'actions', filter: this.isActive }
        ],
        items: []
      },
      event_uuid: this.$route.params.event_uuid,
      sessionToDeactivate: null,
      showGiveAccessForm: false,
      session: null,
      showSearch: false,
      selectedSessions: []
    }
  }

  @Watch('sessions')
  onSessionsChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
    }
  }

  async mounted () {
    const { event_uuid } = this.$route.params
    await Promise.all([this.$store.dispatch('sessions/fetchAttendanceCounts', { event_uuid }), this.$store.dispatch('email/fetchEmails', event_uuid), this.fetchSessions()])
  }

  isActive (value, search, item) {
    return !!item.active
  }

  handleSessionDeactivate (payload: any) {
    this.$data.sessionToDeactivate = payload
  }

  parseSessionSpeakers (speakers: any[]): string {
    let speakerString = ''
    for (let i = 0; i < speakers.length; i++) {
      speakerString += `${speakers[i].speaker_condensed.first_name} ${speakers[i].speaker_condensed.last_name}`
    }
    return speakerString
  }

  /**
   * deativates a session.
   *
   * @param { confirm: any }
   */
  async onDeactivateSessionAction ({ confirm }: { confirm: any }): Promise<void> {
    if (confirm) {
      try {
        this.loading = true
        const payload: any = {
          event_uuid: this.$data.event_uuid,
          session_uuid: this.$data.sessionToDeactivate.uuid,
          data: {
            name: this.$data.sessionToDeactivate.name,
            active: 0
          }
        }
        await this.$store.dispatch('sessions/updateSession', payload)
        Container.get(Notification).success('Session successfully deactivated.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.loading = false
      }
    }
    this.$data.sessionToDeactivate = null
  }

  async deleteSessions () {
    try {
      this.loading = true
      const response = await this.$store.dispatch('sessions/deleteSessions', { event_uuid: this.$route.params.event_uuid, data: { records: this.$data.selectedSessions } })
      Container.get(Notification).success('Deleted Sessions')
      this.$data.selectedSessions = []
      this.fetchSessions()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  async exportParticipantsAttendanceReport () {
    try {
      this.loading = true
      const response = await this.$store.dispatch('sessions/getParticipantsAttendanceReport', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  async exportCoursesReport () {
    try {
      this.loading = true
      const response = await this.$store.dispatch('sessions/getExportCourses', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  async exportAllScansReport () {
    try {
      this.loading = true
      const response = await this.$store.dispatch('sessions/exportAllScansReport', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  async exportAllSessionScans () {
    try {
      this.loading = true
      const response = await this.$store.dispatch('sessions/exportAllSessionScans', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  handleShowGiveAccessForm (session) {
    this.$data.showGiveAccessForm = true
    this.$data.session = session
  }

  // TODO: re-fetch session data on close.
  handleCloseGiveAccessForm () {
    this.$data.showGiveAccessForm = false
    this.$data.session = null
    this.fetchSessions()
  }

  handleEmailAttendeesModalOpen (sessionId: string) {
    this.$data.showEmailAttendeesModal = true
    this.$data.showEmailAttendeesModalSessionId = sessionId
  }

  handleEmailAttendeesModalClose () {
    this.$data.showEmailAttendeesModal = false
    this.$data.emailToSend = ''
    this.$data.showEmailAttendeesModalSessionId = ''
  }

  async handleSendEmail () {
    try {
      await this.sendEmail()
      Container.get(Notification).success('Email successfully sent')
    } catch (e) {
      Container.get(ErrorHandlerService).error('There was a problem sending your email. Please try again.')
    } finally {
      this.handleEmailAttendeesModalClose()
    }
  }

  async sendEmail () {
    const participant_uuids = this.sessionParticipantUuidsHash[this.$data.showEmailAttendeesModalSessionId] || []
    if (participant_uuids.length) {
      await this.$store.dispatch('email/sendBulkEmail', {
        event_uuid: this.$route.params.event_uuid,
        participant_uuids,
        email_name: this.$data.emailToSend
      })
    }
  }

  private calculateSessionStatus (session: Record<string, any>): string {
    return session.session_status
  }

  private async fetchSessions () {
    try {
      this.loading = true
      await this.$store.dispatch('sessions/fetchSessions', { event_uuid: this.$data.event_uuid })
      // if this.$data.session is not null. that means the modal is open. so reset it after update.
      if (this.$data.session !== null) {
        const [session] = this.sessions.filter(sesh => sesh.uuid === this.$data.session.uuid)
        this.$data.session = session
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  get accessLevel () {
    let securityContext = Container.get(GtrStorage).getItem('security_context')
    securityContext = JSON.parse(securityContext)
    return securityContext?.access_level
  }
}
